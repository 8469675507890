import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import { Bar } from 'react-chartjs-2';
import { makeStyles, styled } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  IconButton,
  Collapse
} from '@material-ui/core';
import {Link} from 'react-router-dom'
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import data from 'mockup/salesData'
import ListBids from "./ListBids";
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";

// import { data, options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const LatestBids = props => {
  const { className, ...rest } = props;
  const sm = useMediaQuery('(max-width: 600px)');
  const [data, setDate] = useState([]);
  const svgRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  useEffect(() => {
    axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_BILL_TO_DO, { params: { limit: 3, pageNumber: 1, pageSize: 3 } })
        .then(res => {
          setDate(res.data.data);
        })
  }, [])

  useEffect(() => {
    if(svgRef.current){
      setHeight(svgRef.current.height);
    }
  },[svgRef])
  return (
    <Card

        ref={svgRef}
      {...rest}
      className={clsx(classes.root, className)}
      style={{position: 'relative', display: 'flex', flexDirection:'column', alignItems: 'stretch'}}
    >
      <CardHeader
        title=" BIDS TO DO"
        action={
          sm && <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        style={ sm ? { padding: '12px 16px 8px' } : null } />
      <Divider />
      {
        sm
          ? <Collapse in={expanded} timeout="auto" unmountOnExit>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <ListBids  data={data} />
              </div>
            </Collapse>
          : <ListBids  data={data} />
      }
      <div >
        <Divider  style={{width: '100%', position : 'initial', bottom: '48px'}}/>
        <CardActions
            style={{width: '100%', position: 'initial', bottom: 0}}
            className={classes.actions}>
          <Link
                to={`/customers/bidtodo`}>
            <Button
                color="primary"
                size="small"
                variant="text"
            >
              View All <ArrowRightIcon />
            </Button>
          </Link>

        </CardActions>
      </div>

    </Card>
  );
};

LatestBids.propTypes = {
  className: PropTypes.string
};

export default LatestBids;
