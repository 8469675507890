import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {
    CardContent,
    Grid,
    Button,
    colors,
    Typography, CircularProgress,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {DatePicker} from "@material-ui/pickers";
import moment from "moment";
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { AddressLink } from 'components'

const useStyles = makeStyles(theme => ({
    sale: {
        position: "relative",
        borderBottom: '1px solid #eeeeee',
        '&:last-child': {
            borderBottom: 'none'
        }
    },
    actionBtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        '& button': {
            fontSize: "12px"
        }
    },
    button: {
        padding: '5px 10px',
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    actionBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: '#0000001f'
    },
    addressLink: {
        textDecoration: 'underline'
    }
}))

const ListSales = props => {
    const {data, onLoad} = props
    const classes = useStyles();
    const [openPicker, setOpenPicker] = useState(false);
    const [date, setDate] = useState('');
    const [selected, setSelected] = useState({})
    const [isSaving, setIsSaving] = useState({});
    const [isStatus, setIsStatus] = useState({failed: false, msg: ''});
    const [openSnackbar, setOpenSnackBar] = useState(false);

    const sm = useMediaQuery('(max-width: 415px)');
    const sm_down = useMediaQuery('(max-width: 959px)');
    const updateSFU = (date) => {
        setIsSaving({[selected]: true})
        const data = {
            id: selected,
            dateCompleted: new Date(date).toISOString()
        }
        axios.patch(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_FOLLOW_UP_CONTACT_MARK_COMPLETE, data)
            .then(res => {
                setIsStatus({failed: false, msg: 'Marked complete.'});
                setOpenSnackBar(true)
            })
            .catch(err => {
                setIsStatus({failed: true, msg: 'Update failed, please try again later'});
                setOpenSnackBar(true);
            }).finally(() => {
                setIsSaving({[selected]: false})
                onLoad()
        })
    }
    return (
        <>
            {data && data.map((sale, index) => (
                <CardContent className={classes.sale} key={index}>
                    { isSaving[sale.id] && <div className={classes.actionBox}><CircularProgress size={32}/></div>}
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography gutterBottom variant='h4'>{sale.companyName}</Typography>
                            <Typography gutterBottom>
                                {sale.jobsiteContactName}
                            </Typography>
                            <Typography gutterBottom>
                                <AddressLink
                                    address={{
                                        ...sale,
                                        state: { name: sale.state }
                                    }}
                                />
                            </Typography>
                            <Typography gutterBottom>
                                {sale.jobsiteContactPhone}
                            </Typography>
                            <Typography gutterBottom>
                                {sale.jobsiteContactEmail}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={4} md={4} style={{ maxHeight: '390px',overflow:'auto' }} >
                            <Typography gutterBottom>
                                <strong>Note: </strong>{sale.followUpNote}
                            </Typography>
                            <Typography gutterBottom>
                                <strong>Job Description: </strong>{sale.jobDescription}
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography gutterBottom>
                                <strong>Follow Up
                                    Date: </strong>{sale.followUpDate ? moment(sale.followUpDate).format('MM/DD/YYYY') : ''}
                            </Typography>
                            <Typography gutterBottom>
                                <strong>Last Job
                                    Date: </strong>{sale.lastJobDate ? moment(sale.lastJobDate).format('MM/DD/YYYY') : ''}
                            </Typography>
                            <Typography gutterBottom>
                                <strong>Job Created Date: </strong>{sale.jobCreatedDate ? moment(sale.jobCreatedDate).format('MM/DD/YYYY') : ''}
                            </Typography>
                            <Typography gutterBottom>
                                <strong>Created By: </strong>{sale.createdBy}
                            </Typography>
                            <div className={classes.actionBtn}>
                                <Button style={{marginBottom: '10px'}} className={classes.button}
                                        onClick={() => {
                                            setSelected(sale.id);
                                            setOpenPicker(true);
                                        }}
                                >
                                    Mark Complete
                                </Button>
                                {/*<Link target='_blank' to={`/customers/${sale.customer}/155067a5-15b6-4725-bc42-d7b2d681c338/information`}>*/}
                                <Link
                                      to={`/customers/${encodeURIComponent(sale.companyName)}/${sale.customerId}/jobs/${sale.jobId}/information`}>
                                    <Button
                                        style={{width: '100%'}}
                                        className={classes.button}
                                        variant="contained">
                                        View Job
                                    </Button>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            ))
            }
            <DatePicker
                style={{display: 'none'}}
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                onChange={date => updateSFU(date)}
                variant='dialog'
                value={date ? date : new Date()}
            />
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={openSnackbar}
                      autoHideDuration={3000}
                      onClose={() => setOpenSnackBar(false)}>
                <Alert
                    elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography
                        color="inherit"
                        variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

ListSales.propTypes = {
    data: PropTypes.array
}

export default ListSales;
