import React, { useRef, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, Tooltip, Snackbar, IconButton } from '@material-ui/core'
import { EditRounded } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import cx from 'classnames'
import moment from 'moment'
import { useDrag } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux'
import apiConfig from 'apiConfig'
import axios from 'utils/axios'
import { useParams } from "react-router-dom";
import { formatNumber } from "utils/formatNumber";
import { AddressLink, ConfirmModal } from 'components'

const useStyles = makeStyles(theme => ({
    content: {
        display: '-webkit-box',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.white,
        padding: '3px',
        lineHeight: '22px',
        paddingRight: '40px',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        maxHeight: '50px',
        height: 'auto',
        userSelect: 'none'

    },
    hiddenContent: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
    resizableBox: {
        position: 'absolute'
    },
    resize: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '3px',
        height: '100%',
        backgroundColor: '#000000',
        cursor: 'e-resize'
    },
    statusJob: {
        position: 'absolute',
        color: 'white',
        right: '25px',
        top: '0px', height: '100%',
        '& svg': {
            position: 'absolute',
            margin: 'auto',
            top: '0',
            bottom: '0',
            left: '-24px',
            right: 10
        }
    },
    tooltip2: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        '&::before': {
            border: 'solid',
            borderColor: '#444 transparent',
            content: `""`,
            left: '48%',
            position: 'absolute',
            zIndex: '2',
        },
        '&::after': {
            fontSize: '14px',
            display: 'flex',
            content: 'attr(miles)',
            background: 'rgba(0,0,0,0.7)',
            color: '#fff',
            margin: '0 auto',
            padding: '5px',
            zIndex: '2',
            position: 'absolute',
            justifyContent: 'center',
            left: 0,
            right: 0,
            width: 'max-content',
            borderRadius: '4px',
        }
    },
    distance1: {
        '&::before': {
            borderWidth: '12px 6px 0px 6px',
            top: '-10px',
        },
        '&::after': {
            top: '-37px'
        }
    },
    distance2: {
        '&::before': {
            borderWidth: '26px 6px 0px 6px',
            top: '-26px',
        },
        '&::after': {
            top: '-52px'
        }
    },
    distance3: {
        '&::before': {
            borderWidth: '37px 6px 0px 6px',
            top: '-37px',
        },
        '&::after': {
            top: '-63px'
        }
    },
    jobStyle: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        overflow: 'hidden',
        borderRadius: '15px',
        padding: '0 8px',
        border: '1px solid #ffffff',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minHeight: '30px',
        position: 'relative',
        zIndex: 1
    },
    jobIndex: {
        zIndex: 0
    },
    topJobIndex: {
        zIndex: 2
    },
    iconsRight: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        width: 25,
        height: '100%',
        padding: '5px 0',
        '& a': {
            color: 'white',
        }
    },
    editIcon: {
        padding: 0,
        marginBottom: 5,
        "&:hover": {
            backgroundColor: 'transparent'
        },
        "& span svg": {
            color: '#fff',
            fontSize: "18px"
        }
    },
    addressLink: {
        color: 'white',
        fontSize: 16
    },
    fieldsetFlex: {
        zIndex: 2,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: '0 8px',
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: '15px !important',
        pointerEvents: 'none',
        border: '3px dotted blue',
        color: 'blue',
        fontSize: '0.75em',
        fontWeight: 'bold'
    },
    legendFlex: {
        backgroundColor:'blue',
        color: 'white',
        textWrap: 'nowrap',
        position: 'relative',
        bottom: '0.1em',
        overflow: 'hidden',
        borderRadius: '10px'
    },
    customerService: {
        '& $content, & $editIcon span svg, & $iconsRight a': {
            color: theme.palette.black
        }
    }
}));

const customThemeTooltip = createTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '14px',
                maxWidth: '90vw'
            }
        }
    }
})

let mounted = false;
const tops = ['33%', '51%', '66%']

const ResizableBox = props => {
    const { date } = useParams();
    const classes = useStyles();
    const content = useRef();
    const resizeRef = useRef();
    const dispatch = useDispatch();

    const distances = [classes.distance1, classes.distance2, classes.distance3]

    const { index, routeId, job, isOver, hourOver, style, startTime, canDrag, topJobId, changeTopJob, editJob,colorInput ,disableJobResizing} = props;

    const { routes } = useSelector(state => state.RoutesReducer)
    const { isDragging } = useSelector(state => state.JobsReducer);
    const { showDistance } = useSelector(state => state.RoutesReducer);

    const [isStatus, setStatus] = useState({ failed: false, msg: '' });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [width, setWidth] = useState(0);
    const [isDrag, setIsDrag] = useState(true);
    const [currentJob, setCurrentJob] = useState(job);
    const [isCommercial, setIsCommercial] = useState(false);
    const [translate, setTranslate] = useState(0)
    const [openConfirmResizeModal, setOpenConfirmResizeModal] = useState(false);

    const jobColor = useMemo(() => {
        if (isOver)
            return '#a5a5a5';
        else if (job?.job?.completedDate) 
            return '#b0b0b0'; 
        else if (job?.job?.isCustomerService)
            return '#ffef9f';
        else if (isCommercial)
            return '#8a2be2'; 
        return '#4caf50'; 
    }, [job, isOver, isCommercial]);

    const jobDuration = useMemo(
        () => routes && job ? moment.duration(moment(job.jobEnd).diff(moment(job.jobStart))).asHours() : 0,
        [job , routes]
    );

    const [{ status, type }, drag] = useDrag({
        item: { id: job?.jobSplitId || '', hours: job?.estimatedHours || 1, type: 'Job', oldRouteId: routeId, isRecurringJob: !!job?.recurParentSplitId },
        canDrag: canDrag,
        collect: (monitor) => ({
            type: monitor.isDragging() ? 'jobs' : '',
            status: monitor.isDragging() ? true : false
        })
    });

    const handleOnDrag = event => {
        if (window.innerHeight - event.clientY <= 50) {
            const routeContainer = document.getElementById('content');
            routeContainer.scrollTop += 15
        }
    }

    const resizeJob = () => {
        setOpenConfirmResizeModal(false);
        const range = width % 50;
        const hrs = range < 25 ? (width - range) / 100 : (width + (50 - range)) / 100;
        dispatch({ type: 'IS_UPDATE', status: true });


        const route = routes.find(route => route.id === routeId);
        const job = { ...currentJob };

        const localEndDate = new Date((new Date(date)).toISOString().replace("Z", ""));
        const hour = startTime + (hrs);
        localEndDate.setHours((hour) - Math.floor(hour) > 0.5 ? Math.floor(hour) + 1 : Math.floor(hour));
        localEndDate.setMinutes((hour) - Math.floor(hour) > 0 && (hour) - Math.floor(hour) <= 0.5 ? 30 : 0);
        const jobEnd = new Date(localEndDate).toISOString();
        job.jobEnd = moment.utc(jobEnd).local().toISOString();
        job.estimatedHours = hrs;
        job.job.estimatedHours = hrs * (disableJobResizing ? 1 : (route.routeEmployees.length == 0 ? 1 : route.routeEmployees.length));

        const routeJob = route.routeJobs.filter(job => job.id !== currentJob.id)
        const newRouteData = {
            ...route,
            routeEquipment: !route.routeEquipment ? [] : [...route.routeEquipment],
            routeEmployees: !route.routeEmployees ? [] : [...route.routeEmployees],
            routeJobs: route.routeJobs ? [...routeJob, job] : [job],
        }
        axios.put(apiConfig.url.BASE_URL + apiConfig.url.ROUTE + `${date}/` + routeId, newRouteData)
            .then(() => {
                setWidth(hrs * 100);
                dispatch({ type: 'CHANGE_JOB_HOURS', oldRouteId: routeId, routeId, job });
            })
            .catch(() => {
                setWidth(job.estimatedHours * 100);
                setStatus({ failed: true, msg: 'Update failed, please try again later.' });
                setOpenSnackbar(true)
            })
            .finally(() => dispatch({ type: 'IS_UPDATE', status: false }))
    }

    const cancelResizeJob = () => {
        setOpenConfirmResizeModal(false);
        calculateWidth();
    }

    const calculateWidth = () => {
        const route = routes.find(route => route.id === routeId);
        setWidth(jobDuration * 100);
        setWidth(jobDuration * 100);
        if (route) setIsCommercial(route.isCommercial)
    }

    useEffect(() => {
        if (!canDrag) return;
        const resizeEle = resizeRef.current;
        let curX;
        const mousedown = e => {
            window.addEventListener('mousemove', mousemove);
            window.addEventListener('mouseup', mouseup);
            curX = e.clientX;
            setIsDrag(false);
        }
        const mousemove = e => {
            const newX = e.clientX - curX;
            setWidth(width + newX);
        }
        const mouseup = e => {
            window.removeEventListener('mousemove', mousemove);
            setIsDrag(true);
        }

        resizeEle.addEventListener('mousedown', mousedown);
        resizeEle.addEventListener('mouseup', mouseup);

        return () => {
            resizeEle.removeEventListener('mousedown', mousedown);
            resizeEle.removeEventListener('mouseup', mouseup);
        }
    }, [width])

    useEffect(() => {
        mounted = true;
        mounted && dispatch({ type: 'CHANGE_STATUS_JOB', status: status })
        return () => mounted = false;
    }, [status]);

    useEffect(() => {
        mounted = true;
        dispatch({ type: 'DRAG_ITEM', onDragType: type })
        return () => mounted = false;
    }, [type]);

    // change estimatedHours after resize job's hour
    useEffect(() => {
        mounted = true;
        if (isDrag && width) {
            setOpenConfirmResizeModal(true);
        }

        return () => mounted = false;
    }, [isDrag])

    // set width of the job after render
    useEffect(() => {
        mounted = true;
        if (job && mounted) {
            setCurrentJob(job);

            calculateWidth();

            const start = new Date(job.jobStart)
            const starttime = start.getHours() + start.getMinutes() / 60
            setTranslate(starttime * 100)
        }
        return () => mounted = false;
    }, [job, routes])

    return (
        <>
            <MuiThemeProvider theme={customThemeTooltip}>

                <div style={{ left: `${translate}px`, top: index * 55 }}
                    className={`${classes.resizableBox} ${job?.job?.isCustomerService && !job?.job?.completedDate ? classes.customerService : null} ${isDragging ? classes.jobIndex : (job && job.jobSplitId === topJobId ? classes.topJobIndex : '')}`}>

                    <div miles={job && job.job?.bidStageId !== 5 && job.job?.completedDate == null ? (isNaN(job.distance) ? `0 Miles` : `${colorInput && colorInput.RedMiles && colorInput.RedMiles > 0 
                                                                            && parseFloat(job.distance) > 0 && ((colorInput.RedMiles * 2) < parseFloat(job.distance)) ? "~ " : ""}
                                                                            ${formatNumber(job.distance, 1)} Miles`) : ''}
                        className={showDistance && job ? cx(classes.tooltip2, distances[index]) : null}>
                        <div onDrag={handleOnDrag}
                            onClick={e => {
                                e.stopPropagation()
                                changeTopJob()
                            }}
                            className={classes.jobStyle}
                            ref={job ? (isDrag ? drag : null) : null}
                            style={{
                                ...style,
                                display: job ? (status ? 'none' : 'flex') : (isOver ? 'flex' : 'none'),
                                width: job ? `${width}px` : `${hourOver * 100}px`,
                                backgroundColor: jobColor,
                                cursor: canDrag ? 'pointer' : 'not-allowed'
                            }}
                        >
                            <Tooltip
                                arrow
                                interactive
                                disableTouchListener
                                title={status ? '' :
                                    <div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: 12
                                        }}>
                                            <Typography>
                                                {job?.job?.address1}
                                            </Typography>
                                            <Typography>
                                                {job && job.job && job.job.totalSplitJobs > 1 ? `(1/${job.job.totalSplitJobs})` : ''}&nbsp;
                                                <IconButton disabled={!canDrag} className={classes.editIcon} onClick={e => {
                                                    e.stopPropagation()
                                                    editJob()
                                                }}>
                                                    <EditRounded />
                                                </IconButton>
                                            </Typography>
                                        </div>
                                        <Typography color="inherit">
                                            {`${job?.job?.city ?? ''} ${job?.job?.state ?? ''}, $${formatNumber(job?.job?.price ?? 0, 2)}`}
                                        </Typography>
                                        <Typography style={{position: 'relative'}}>
                                            <em>Start time: </em> {job ? moment(job.jobStart).format('LT') : ''}
                                            <a style={{ color: "white", position: 'absolute', right: '0', top: '0'}} href={`/customers/${job && job.job ? encodeURIComponent(job.job.customerName) : ''}/${job && job.job ? job.job.customerId : ''}/jobs/${job && job.job ? job.job.jobId : ''}/information?schedule=${date}`}
                                               onClick={e => e.stopPropagation()}>
                                                <i className="fas fa-external-link" />
                                            </a>
                                        </Typography>
                                        <Typography>
                                            <em>Hours: </em> {job && job.estimatedHours ? job.estimatedHours.toFixed(2) : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Customer: </em> {(job && job.job) ? job.job.customerName : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Address: </em> {job?.job
                                                ? <AddressLink
                                                    address={{
                                                        ...job.job,
                                                        state: { name: job.job.state }
                                                    }}
                                                    className={classes.addressLink}
                                                    inline
                                                />
                                                : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Equipment: </em> {(job && job.job && job.job.equipment) ? job.job.equipment.replace(";", ", ") : ''}
                                        </Typography>
                                        <Typography>
                                            <em>Description: </em> {job ? job.job && job.job.description && job.job.description.substr(0,Math.min(job.job.description.length,200)) : ''}
                                        </Typography>
                                        <Typography>
                                            <span>
                                                {
                                                    job && job.routeJobStatus === 2 && (
                                                        <>
                                                            <i className="far fa-walking" /> <span>On the way</span>
                                                        </>
                                                    )
                                                }
                                                {
                                                    job && job.routeJobStatus === 1 && (
                                                        <>
                                                            <i className="far fa-house-return" /> <span>Job Started</span>
                                                        </>
                                                    )
                                                }
                                                {
                                                    job && job.routeJobStatus === 3 && (
                                                        <>
                                                            <i className="far fa-house-leave" /><span>Job completed</span>
                                                        </>
                                                    )
                                                }
                                            </span>
                                        </Typography>
                                    </div>
                                }>
                                <span ref={content} className={classes.content}>
                                    {props.children}
                                </span>
                            </Tooltip>
                            <span className={classes.statusJob}>
                                {
                                    job && job.routeJobStatus === 2 &&
                                    <div><i className="far fa-walking" /></div>
                                }
                                {
                                    job && job.routeJobStatus === 1 &&
                                    <div><i className="far fa-house-return" /></div>
                                }
                                {
                                    job && job.routeJobStatus === 3 &&
                                    <div><i className="far fa-house-leave" /></div>
                                }
                            </span>
                            <p className={classes.iconsRight}>
                                <IconButton disabled={!canDrag} className={classes.editIcon} onClick={e => {
                                    e.stopPropagation()
                                    editJob()
                                }}>
                                    <EditRounded />
                                </IconButton>
                                <Tooltip arrow title={'View'}>
                                    <a href={`/customers/${job && job.job ? encodeURIComponent(job.job.customerName) : ''}/${job && job.job ? job.job.customerId : ''}/jobs/${job && job.job ? job.job.jobId : ''}/information?schedule=${date}`}
                                        onClick={e => e.stopPropagation()}>
                                        <i className="fas fa-external-link" />
                                    </a>
                                </Tooltip>
                            </p>
                            {
                                canDrag &&
                                <span ref={resizeRef} className={classes.resize}></span>
                            }
                        </div>
                        {job && job.job.flexibleScheduling && 
                        <fieldset aria-hidden="true" className={classes.fieldsetFlex} style={{ top : width >= 100 ? '-6px' : '0px'}}>
                            {
                                width >= 100 && 
                                <legend className={classes.legendFlex}>
                                    <span>Anytime Today</span>
                                </legend>
                            }
                            
                        </fieldset>}
                    </div>
                </div>
            </MuiThemeProvider>

            <ConfirmModal
                title='Are you sure you want to change the Estimated Hours value on the job itself?'
                openConfirm={openConfirmResizeModal}
                closeConfirm={cancelResizeJob}
                onConfirm={resizeJob}
            />

            <Snackbar open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert elevation={6} variant='filled' severity={isStatus.failed ? 'error' : 'success'}>
                    <Typography color="inherit" variant="h6">
                        {isStatus.msg}
                    </Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

ResizableBox.propTypes = {
    index: PropTypes.number,
    job: PropTypes.object,
    hours: PropTypes.number,
    isOver: PropTypes.bool,
    hourOver: PropTypes.number,
    style: PropTypes.object,
    topJobId: PropTypes.string,
    changeTopJob: PropTypes.func,
    editJob: PropTypes.func
}

ResizableBox.defaultProps = {
    style: {}
}

export default ResizableBox;
