import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import { Page } from 'components';
import {
    Information, Pricing, ContractsAndForms,
    Communications, Equipment, Advertising, Integrations,
    Miscellaneous
} from './components'
import localStorage from 'utils/localStorage'
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";
import { checkFeature, FeatureFlags } from 'FeatureService';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        padding: '0 32px 32px',
    },
    tabs: {
        marginTop: theme.spacing(2)
    },
    tab: {
        padding: '6px 16px',
        [theme.breakpoints.up('sm')]: {
            minWidth: 'auto'
        },
    },
    divider: {
        backgroundColor: colors.grey[300]
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));

let mounted = false;
const Admin = props => {

    const { match, history } = props;
    const classes = useStyles();
    const { name, id, tab } = match.params;

    const account = localStorage.getUserInfo();
    const fullInfo = localStorage.getFullInfo();
    const [enableCommunications, setEnableCommunications] = useState(true)
    const handleTabsChange = (_, value) => {
        history.push(value);
    };

    useEffect(() => {
        mounted = true;

      (async () => {
        const isEnableCommunications = await checkFeature(FeatureFlags.COMMUNICATION_CENTER);
        setEnableCommunications(isEnableCommunications)
      }
      )();
      return () => mounted = false;
    }, [])
    const tabs = [
        { value: 'information', label: 'Information' },
        { value: 'pricing', label: 'Pricing' },
        { value: 'contractsandforms', label: 'Contracts & Forms' },
        { value: 'communications', label: 'Communications' },   
        { value: 'equipment', label: 'Equipment' },
        { value: 'advertising', label: 'Advertising' },
        { value: 'integrations', label: 'Integrations' },
        { value: 'miscellaneous', label: 'Miscellaneous' },
    ];

    if (!tab) {
        return <Redirect to={`/admin/${(account && account.displayName) ? account.displayName : ' '}/${fullInfo.cid}/information`} />;
    }
    if (!tabs.find(t => t.value === tab)) {
        return <Redirect to="/errors/error-404" />;
    }
    
    
    return (
        <AccessControl requiredPermission={PERMISSIONS.page.admin} redirect>
            <Page
                className={classes.root}
                title={name === ' ' ? 'Admin Detail' : `Admin Detail`}
            >
                <Tabs
                    className={classes.tabs}
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    value={tab}
                    variant="scrollable"
                >
                    {tabs.filter((el) => {
                       return enableCommunications ? el.value !== 'communications' : true;
                    }).map(tab => (
                        <Tab
                        className={classes.tab}
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                    />
                    ))}
                </Tabs>
                <Divider className={classes.divider} />
                <div className={classes.content}>
                    {tab === 'information' && <Information adminId={id} />}
                    {tab === 'pricing' && <Pricing adminId={id} />}
                    {tab === 'contractsandforms' && <ContractsAndForms adminId={id} />}
                    {tab === 'communications' && <Communications adminId={id} />}
                    {tab === 'equipment' && <Equipment adminId={id} />}
                    {tab === 'advertising' && <Advertising adminId={id} />}
                    {tab === 'integrations' && <Integrations adminId={id} />}
                    {tab === 'miscellaneous' && <Miscellaneous adminId={id} />}
                </div>
            </Page>
        </AccessControl>
    );
};

Admin.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export default Admin;
