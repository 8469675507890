import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar
} from '@material-ui/core';
import moment from "moment";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: 'none'
    }
  },
  contentLeft: {
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      flexDirection: 'column'
    }
  },
  avatar: {
    height: 120,
    width: 120,
    [theme.breakpoints.down('sm')]: {
      height: 100,
      width: 100
    }
  },
  infor: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
}));

const EmployeeCard = props => {

  const xs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { className, user, ...rest } = props;

  const classes = useStyles();

  const nameUser = (user.fullName && user.fullName.replace('N/A', ' ')) || ' ';

  return (
    <Link to={`/employees/${encodeURIComponent(nameUser)}/${user.id}/information`}>
      <Card {...rest}>
        <CardContent className={classes.content}>
          <Grid container spacing={2} className={classes.cusFont}>
            <Grid
              item
              xs={12}
              md={6}
              className={classes.contentLeft}>
              <Avatar
                variant={xs ? '' : 'rounded'}
                className={classes.avatar}
                src={user.avatar || ''} />
              <div className={classes.infor}>
                <Typography
                  gutterBottom
                  variant="h4">
                  {user.lastName || ''}, {user.firstName || ''}
                </Typography>

                {/*<Typography gutterBottom>*/}
                {/*  <strong>User Name: </strong>*/}
                {/*  {user.userName}*/}
                {/*</Typography>*/}

                <Typography gutterBottom>
                  {!xs && <strong>Address: </strong>}
                  {user.address != null ? user.address.address1 : "N/A"}
                </Typography>
                <Typography gutterBottom style={{paddingLeft: xs ? 0 : '60px'}}>
                  {user.address != null ? `${user.address.city ? user.address.city + ', ': ''}${user.address.state ? user.address.state.code + ', ' : ''}${user.address.zipCode || ''}` : ""}
                </Typography>
              </div>
            </Grid>

            <Grid item
              xs={12}
              md={6} className={classes.infor}>
              <Typography gutterBottom>
                <strong>Email: </strong>{user.email || ''}
              </Typography>
              <Typography gutterBottom>
                <strong>Phone: </strong>
                {(user.phones && user.phones[0] && user.phones[0].formattedPhoneNumber) || 'N/A'}
              </Typography>
              {
                user.archived &&
                <Typography gutterBottom>
                  <strong>Employee Status: </strong>
                  <span style={{ color: 'red' }}>Archived</span>
                </Typography>
              }
              <Typography gutterBottom>
                {
                  user.termDate && (
                      <>
                        <strong>Terminated: </strong>
                        {(user.termDate ? moment(user.termDate).format('MM/DD/YYYY') : '')}
                      </>
                  )
                }
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Link>
  );
};

EmployeeCard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default EmployeeCard;
