import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Collapse, colors, Divider, FormControlLabel, Grid, Snackbar, TextField, Typography } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import apiConfig from 'apiConfig'
import { GreenCheckbox } from 'components'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import axios from 'utils/axios'

const useStyles = makeStyles(theme => ({
  cardHeader: {
    cursor: 'pointer',
  },
  btn: {
    whiteSpace: 'nowrap',
    padding: '4px 14px',
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900],
    },
  },
  btnDis: {
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgb(217 217 217)',
    pointerEvents: 'none',
  },
}))

const Miscellaneous = ({ adminId }) => {
  const classes = useStyles()

  const [admin, setAdmin] = useState()
  const [disableJobResizing, setDisableJobResizing] = useState()
  const [fieldTechLookAheadDays, setFieldTechLookAheadDays] = useState()
  const [expandedScheduling, setExpandedScheduling] = useState(true)
  const [expandedEmployees, setExpandedEmployees] = useState(true)
  const [saveScheduling, setSaveScheduling] = useState(false)
  const [saveEmployees, setSaveEmployees] = useState(false)
  const [isStatus, setIsStatus] = useState({ failed: false, msg: '' })
  const [openSnackbar, setOpenSnackBar] = useState(false)

  const handleSaveScheduling = () => {
    setSaveScheduling(true)
    updateCompany({ ...admin, disableJobResizing }).finally(() => setSaveScheduling(false))
  }

  const handleSaveEmployees = () => {
    setSaveEmployees(true)
    updateCompany({ ...admin, fieldTechLookAheadDays }).finally(() => setSaveEmployees(false))
  }

  const updateCompany = (data) => {
    return axios.put(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_UPDATE.replace('{companyId}', adminId), data)
      .then(res => {
        setAdmin(data)
        setIsStatus({ failed: false, msg: 'Record Saved' })
        setOpenSnackBar(true)
      })
      .catch(err => {
        setIsStatus({ failed: true, msg: 'An error occurred, please try again later' })
        setOpenSnackBar(true)
      })
  }

  useEffect(() => {
    axios.get(apiConfig.url.BASE_URL + apiConfig.url.COMPANY_INFO + adminId)
      .then(res => {
        setAdmin(res.data)
        setDisableJobResizing(res.data.disableJobResizing)
        setFieldTechLookAheadDays(res.data.fieldTechLookAheadDays)
      })
  }, [adminId])

  if (!admin) {
    return (
      <Grid
        container
        spacing={0}
        align="center"
        justifyContent="center"
        direction="column"
      >
        <Grid item>
          <CircularProgress className={classes.circularProgress} size={50} />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Card>
        <CardHeader
          title="Scheduling Options"
          className={classes.cardHeader}
          onClick={() => setExpandedScheduling(!expandedScheduling)}
          action={<KeyboardArrowDown />}
        />
        <Divider />
        <Collapse in={expandedScheduling} timeout="auto" unmountOnExit>
          <CardContent>
            <FormControlLabel
              name="disableJobResizing"
              label="Disable Job resizing when adding 2 or more employees to a route on the schedule"
              checked={disableJobResizing || false}
              control={<GreenCheckbox />}
              onChange={event => setDisableJobResizing(event.target.checked)}
            />
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              style={{ marginLeft: '8px' }}
              className={saveScheduling ? classes.btnDis : classes.btn}
              onClick={handleSaveScheduling}
            >
              Save
            </Button>
            {saveScheduling && <CircularProgress size={24} />}
          </CardActions>
        </Collapse>
      </Card>
      <Card style={{ marginTop: '24px' }}>
        <CardHeader
          title="Employees"
          className={classes.cardHeader}
          onClick={() => setExpandedEmployees(!expandedEmployees)}
          action={<KeyboardArrowDown />}
        />
        <Divider />
        <Collapse in={expandedEmployees} timeout="auto" unmountOnExit>
          <CardContent>
            <NumberFormat
              customInput={TextField}
              thousandSeparator
              allowNegative={false}
              name="price"
              label="Allow Field Technicians to see their assigned routes/jobs into the future for how many days?"
              variant="outlined"
              fullWidth
              value={fieldTechLookAheadDays || ''}
              placeholder="7"
              onValueChange={values => setFieldTechLookAheadDays(values.value)}
            />
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              style={{ marginLeft: '8px' }}
              className={saveEmployees ? classes.btnDis : classes.btn}
              onClick={handleSaveEmployees}
            >
              Save
            </Button>
            {saveEmployees && <CircularProgress size={24} />}
          </CardActions>
        </Collapse>
      </Card>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert elevation={6} variant="filled" severity={isStatus.failed ? 'error' : 'success'}>
          <Typography
            color="inherit"
            variant="h6"
          >
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  )
}

export default Miscellaneous
