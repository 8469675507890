import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import { Money } from '@material-ui/icons';
import axios from "../../../../utils/axios";
import apiConfig from "../../../../apiConfig";
import {formatNumber} from "../../../../utils/formatNumber";
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const ScheduledAmount = props => {
  const { className, ...rest } = props;
  const [schedule, setScheduleCount] = useState(0);

  const classes = useStyles();
  useEffect(() => {
    axios.get(apiConfig.url.BASE_URL + apiConfig.url.DASHBOARD_SCHEDULE_COUNT)
        .then(res => {
          setScheduleCount(res.data);
        })
  }, [])
  return (
    <Link to={`/schedules`}>
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Scheduled Amount
            </Typography>
            <Typography variant="h3">${formatNumber(schedule, 2)}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Money className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </Link>
  );
};

ScheduledAmount.propTypes = {
  className: PropTypes.string
};

export default ScheduledAmount;
