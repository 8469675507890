import { Button, Card, CardContent, CircularProgress, Grid, Snackbar, Typography, colors } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import apiConfig from 'apiConfig.js';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Tokenizer from './tokenizer';
import { Page } from 'components';
import moment from 'moment';
import axiosNoAuth from 'utils/axiosNoAuth';
import { formatNumber } from 'utils/formatNumber';

const useStyles = makeStyles(theme => ({
  page: {
    padding: theme.spacing(4)
  },
  button: {
    padding: '5px 10px',
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgb(217 217 217)'
    }
  },
  textRight:{
    textAlign: 'right'
  }
}));

const Payment = props => {
  const classes = useStyles();
  const { match } = props;
  const { encryptedPaymentInfo } = match.params;

  const [shouldReload, setShouldReload] = useState(true);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [apiKey, setApiKey] = useState();
  const [paymentDetail, setPaymentDetail] = useState();
  const [tokenizer, setTokenizer] = useState();
  const [ip, setIP] = useState('');
  const [serviceFee, setServiceFee] = useState();
  const [alertContent, setAlertContent] = useState({ severity: '', message: '' });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [convivenceFee , setConvivenceFee] = useState(0);
  const [total , setTotal] = useState(0);

  const submit = () => {
    setSubmitting(true);
    tokenizer.submit();
  };

  useEffect(() => {
    setShouldReload(true);
  }, []);

  const tokenizerSubmission = useCallback(resp => {
    switch(resp.status) {
      case 'success':
        // Successful submission
        axiosNoAuth.post(
          apiConfig.url.BASE_URL + apiConfig.url.PAYMENT_APPLY_PAYMENT.replace('{encryptedPaymentInfo}', encryptedPaymentInfo),
          {
            paymentRequest: paymentDetail.celeroPaymentRequest,
            ipAddress: ip || '',
            token: resp.token
          }
        ).then(res => {
          setAlertContent({
            severity: res.data.message === 'Transaction Succeeded' ? 'success' : 'error',
            message: res.data.message
          });
          setOpenSnackbar(true);

          if (res.data.message === 'Transaction Succeeded') {
            setShouldReload(true);
          }
        }).finally(() => setSubmitting(false));
        break;
      case 'error':
        // Encountered an error while performing submission
        console.log(resp.msg)
        setSubmitting(false);
        break;
      case 'validation':
        // Encountered form validation specific errors
        console.log(resp.invalid)
        setSubmitting(false);
        break;
      default:
        setSubmitting(false);
        break;
    }
  }, [encryptedPaymentInfo, ip, paymentDetail]);

  const tokenizerValidCard = useCallback(resp => {
    if (resp.isValid){
      const fee = resp.fees 
      setServiceFee(fee.service_fee);
      const feeSurcharge = (fee?.surcharge || 0)/100;
      const totalAmount = (paymentDetail?.celeroPaymentRequest?.amount || 0) + feeSurcharge;
      if(paymentDetail?.celeroPaymentRequest)
      {
        paymentDetail.celeroPaymentRequest.fee_Surcharge = fee?.surcharge || 0;
        if(paymentDetail.celeroPaymentRequest.fee_Surcharge > 0)
        paymentDetail.celeroPaymentRequest.fee_Surcharge = (paymentDetail.celeroPaymentRequest.fee_Surcharge / 100)
        setPaymentDetail(paymentDetail);
      }
      setConvivenceFee(feeSurcharge);
      setTotal(totalAmount);
    }
    else setServiceFee();
  }, [paymentDetail]);

  useEffect(() => {
    if (!shouldReload) return;

    setShouldReload(false);
    setLoading(true);

    axiosNoAuth.get(
      apiConfig.url.BASE_URL + apiConfig.url.PAYMENT_REQUEST_PAYMENT.replace('{encryptedPaymentInfo}', encryptedPaymentInfo)
    ).then(res => {
      if (res.data?.item1 && res.data?.item2) {
        const pay = res.data.item1;
        setPaymentDetail(pay);
        const feeSurcharge = (pay?.celeroPaymentRequest?.fee_Surcharge || 0);
        const totalAmount = (pay?.celeroPaymentRequest?.amount || 0) + feeSurcharge;
        setConvivenceFee(feeSurcharge);
        setTotal(totalAmount);
        setApiKey(res.data.item2);

      } else {
        alert('Fail to initialize payment process');
      }
    }).catch(e => {
      console.error(e);
      alert('Fail to initialize payment process');
    }).finally(() => setLoading(false));

    axiosNoAuth.get('https://geolocation-db.com/json/').then(res => setIP(res.data.IPv4));
  }, [encryptedPaymentInfo, shouldReload]);

  useEffect(() => {
    if (apiKey && paymentDetail && !(paymentDetail.celeroPaymentRequest?.celeroTransactionId && paymentDetail?.celeroPaymentRequest?.status != "declined") && !tokenizer) {
      setTokenizer(new Tokenizer({
        //url: 'https://sandbox.gotnpgateway.com', // Optional - Only needed if domain is different than the one your on, example: localhost
        apikey: apiKey,
        container: '#container',
        amount: (paymentDetail.celeroPaymentRequest?.amount * 100).toFixed(0),
        tax_amount: (paymentDetail.celeroPaymentRequest?.tax_amount * 100).toFixed(0),
        // Callback after submission request has been made
        // See Advanced -> Methods for breakdown of specific fields
        submission: tokenizerSubmission,
        validCard: tokenizerValidCard,
        settings: {
          payment: {
            calculateFees: true
          },
          styles: {
            '.card' : {
              'flex-wrap': 'nowrap'
            },
            '.card input' : {
              'color' :'#000000de',
              'font-size': '14px'
            },
            '.card .break' : {
                'max-width': '0'
            },
            '.card .fieldset.cc' : {
                'padding-right': '5px !important'
            },
            '.card .fieldset.exp , .card .fieldset.cvv' : {
              'max-width': '75px'
            }
          }
        }
      }));
    }
  }, [apiKey, ip, paymentDetail, tokenizer, tokenizerSubmission, tokenizerValidCard]);

  return (
    <Page title='Payment' className={classes.page}>
      <>
        {loading ? (
          <Grid
            container
            spacing={0}
            align="center"
            justifyContent="center">
            <Grid item>
              <CircularProgress size={50} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Card style={{ height: '100%' }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div style={{ textAlign: 'center' }}>
                        <img style={{ maxHeight: 211 }} alt='' src={paymentDetail?.logoUrl ?? '/images/quote_invoice/Logos/Logo1.jpg'} />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h4">{paymentDetail?.companyName}</Typography>
                      <Typography>
                        {paymentDetail?.billingAddress?.address1}
                      </Typography>
                      <Typography>
                        {paymentDetail?.billingAddress?.city}, {paymentDetail?.billingAddress?.state?.name} {paymentDetail?.billingAddress?.zipCode}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h4">Bill To</Typography>
                      <Typography>
                        {paymentDetail?.billingAddress?.name}
                      </Typography>
                      <Typography>
                        {paymentDetail?.billingAddress?.address1}
                      </Typography>
                      <Typography>
                        {paymentDetail?.billingAddress?.city}, {paymentDetail?.billingAddress?.state?.name} {paymentDetail?.billingAddress?.zipCode}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Invoice #</Typography>
                      <Typography>Date</Typography>
                      <Typography>Invoice Total</Typography>
                      {paymentDetail?.celeroPaymentRequest?.celeroTransactionId && (paymentDetail?.celeroPaymentRequest?.status == "pending_settlement" || paymentDetail?.celeroPaymentRequest?.status == "settled")  && (
                        <>
                          <Typography>Convenience Fees</Typography>
                          <Typography>Total</Typography>
                        </>
                      )}
                      {/* {serviceFee != null && <Typography>Service Fee</Typography>} */}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={classes.textRight}>
                        {paymentDetail?.invoiceId ?? ''}
                        &nbsp;
                      </Typography>
                      <Typography className={classes.textRight}>
                        {moment(paymentDetail?.invoiceDate).format('L')}
                      </Typography>
                      <Typography className={classes.textRight}>
                        ${formatNumber(paymentDetail?.celeroPaymentRequest?.amount, 2)}
                      </Typography>
                      {paymentDetail?.celeroPaymentRequest?.celeroTransactionId && (paymentDetail?.celeroPaymentRequest?.status == "pending_settlement" || paymentDetail?.celeroPaymentRequest?.status == "settled")  && (
                        <>
                          <Typography className={classes.textRight}>${formatNumber(convivenceFee, 2)}</Typography>
                          <Typography className={classes.textRight}>${formatNumber(total, 2)}</Typography>
                        </>
                      )}
                      {/* {serviceFee != null && <Typography>${formatNumber(serviceFee, 2)}</Typography>} */}
                    </Grid>
                    <Grid item xs={4} style={{ alignSelf: 'center', textAlign: 'right' }}>
                      <Button
                        className={classes.button}
                        disabled={!paymentDetail}
                        variant="contained">
                          <Link
                            style={{ color: 'white', textDecoration: 'none' }}
                            component="a"
                            to={'/invoice/pdf/' + encryptedPaymentInfo}
                            target="_blank">
                            View Invoice
                          </Link>
                      </Button>
                    </Grid>
                    
                    {paymentDetail?.celeroPaymentRequest?.celeroTransactionId && paymentDetail?.celeroPaymentRequest?.status != "declined"  ? (
                      <Grid item xs={12}>
                        <Typography><b>Payment status:</b> {paymentDetail.celeroPaymentRequest.status}</Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} style={{paddingTop: '0px'}}> 
                        <div id="container"></div>
                        {paymentDetail?.celeroPaymentRequest?.fee_Variable === false && (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={4}>
                              <Typography>Convenience Fees</Typography>
                              {/* <Typography>Convenience Percent</Typography> */}
                            </Grid>
                            <Grid item xs={4} className={classes.textRight}>
                              <Typography>${formatNumber(convivenceFee, 2)}</Typography>
                              {/* <Typography>{formatNumber(paymentDetail?.celeroPaymentRequest?.fee_Percent, 2)}%</Typography> */}
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid container spacing={3}>
                          <Grid item xs={4} style={{paddingTop: '0px'}} >
                            <Typography>Total</Typography>
                          </Grid>
                          <Grid item xs={4} style={{paddingTop: '0px'}} className={classes.textRight}>
                            <Typography>${formatNumber(total, 2)}</Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                          <Grid item>
                            <Button
                              className={classes.button}
                              disabled={submitting || !paymentDetail}
                              onClick={submit}>
                              Submit
                            </Button>
                          </Grid>
                          {submitting && (
                            <Grid item>
                              <CircularProgress size={24} />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid> 
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          style={{ top: "64px" }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}>
          <Alert
            elevation={6}
            variant="filled"
            severity={alertContent.severity}>
            <Typography color="inherit" variant="h6">
              {alertContent.message}
            </Typography>
          </Alert>
        </Snackbar>
      </>
    </Page>
  );
};

export default Payment;
