import React, { useState } from 'react'
import {
    Card, CardHeader, CardContent, CardActions, Divider,
    Typography, Button, colors, Grid, TextField, CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DatePicker } from '@material-ui/pickers'
import { KeyboardDatePicker, Page } from 'components'
import * as moment from "moment";
import { AccessControl } from "components/AccessControl/AccessControl";
import { PERMISSIONS } from "common/permissions";

import axios from "utils/axios";
import apiConfig from "apiConfig";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { formatNumber } from "utils/formatNumber";
import localStorage from "utils/localStorage";
import ThankYouNodePdf from './ThankYouNodePdf'
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import * as _ from 'underscore';

const useStyles = makeStyles(theme => ({
    fieldTitle: {
        fontWeight: 500,
        marginBottom: 16
    },
    toDate: {
        marginTop: 37,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    btn: {
        color: theme.palette.white,
        backgroundColor: colors.green[600],
        '&:hover': {
            backgroundColor: colors.green[900]
        }
    },
    btnDis: {
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgb(217 217 217)',
        pointerEvents: 'none',
    },
}));

const reports = [
    { id: '', name: '' },
    { id: 1, name: 'Thank You Note Mailing Labels (Avery 5160)' },
    { id: 3, name: 'Advertising And Referrals' },
    { id: 4, name: 'Completed Job List' },
    { id: 6, name: 'Customer Inactivity Report' },
    { id: 7, name: 'Unresolved Customer Service Calls' },
    { id: 9, name: 'NiceJob' }
]

const Report = () => {

    const classes = useStyles();

    const [report, setReport] = useState({ report: '' });
    const [openPicker, setOpenPicker] = useState(false);
    const [typeDate, setTypeDate] = useState('fromDate');
    const [loading, setLoading] = useState(false);
    const userRole = localStorage.getUserRole();

    const onChangeReport = (name, value) => {
        if (name === 'fromDate' && !value) {
            setReport({ ...report, fromDate: null, toDate: null })
        } else {
            if (report.toDate && moment(value) > moment(report.toDate)) {
                setReport({ ...report, [name]: value, toDate: null });
            } else {
                setReport({ ...report, [name]: value });
            }
        }
    }
    const minDate = toDate => {
        const date = new Date(toDate);
        date.setDate(date.getDate() + 1);
        return date;
    }
    const maxDate = fromDate => {
        const date = new Date(fromDate);
        date.setDate(date.getDate() - 1);
        return date;
    }
    const onShowDatePicker = type => {
        setTypeDate(type);
        setOpenPicker(true)
    }
    const onHandleChangeDate = date => {
        setReport({ ...report, [typeDate]: new Date(date).toISOString() })
    }

    var csvDataCustomersInactiveSince = [];
    var csvDataCustomersService = [];
    var csvDataThankYouNote = [];
    var csvDataCompletedJobList = [];
    var csvDataAdvertisingAndReferrals = [];
    var csvDataGlobalAdvertisingAndReferrals = [];
    var csvDataGlobalNiceJobs = [];
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const generatePdfDocument = async (documentData) => {
        const blob = await pdf(
            <ThankYouNodePdf data={documentData} />
          ).toBlob();
        saveAs(blob, "thank_you_note_report.pdf");
      };

    const handleReport = () => {
        if(report.report == 1) {
            setLoading(true);
            var date = new Date().toISOString();
            csvDataThankYouNote.push([moment(date).format('MM/DD/YYYY HH:mm:ss A')]);
            csvDataThankYouNote.push([]);
            csvDataThankYouNote.push(["Customer Name", "Address"]);

            axios.get(apiConfig.url.BASE_URL + apiConfig.url.REPORT_THANK_YOU_NOTE, {
                params: {
                    FromDate: report.fromDate,
                    ToDate: report.toDate
                }
            })
                .then(res => {
                    // FOR EXPORT PDF
                    generatePdfDocument(_.chunk(res.data, 30));
                    // END FOR EXPORT PDF

                    // FOR EXPORT EXCEL
                    // res.data && res.data.length > 0 && res.data.map((item) => {
                    //     csvDataThankYouNote.push([
                    //         (item.customerName ? item.customerName : ""),
                    //         (item.addressName ? item.addressName+" " : "")+(item.address1 ? item.address1+" " : "")+(item.address2 ? item.address2+" " : "")+(item.city ? item.city+", " : "")+(item.state ? item.state+" " : "")+(item.zipCode ? item.zipCode+" " : "")
                    //     ])
                    // })
                    
                    // const ws = XLSX.utils.aoa_to_sheet(csvDataThankYouNote);
                    // const wb = { Sheets: { "Thank You Note Report": ws }, SheetNames: ["Thank You Note Report"] };
                    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    // const data = new Blob([excelBuffer], {type: fileType});
                    // FileSaver.saveAs(data, "thank_you_note_report" + fileExtension);
                    // END FOR EXPORT EXCEL

                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }

        if(report.report == 3) {
            setLoading(true);
            var date = new Date().toISOString();

            var dateFromDate = "";
            if(report.fromDate && report.fromDate != "") {
                var dateFromDateConverted = new Date(report.fromDate).toISOString();
                dateFromDate = moment(dateFromDateConverted).format('MM/DD/YYYY');
            }

            var dateToDate = "";
            if(report.toDate && report.toDate != "") {
                var dateToDateConverted = new Date(report.toDate).toISOString();
                dateToDate = moment(dateToDateConverted).format('MM/DD/YYYY');
            }

            csvDataAdvertisingAndReferrals.push(["Referral Source for "+dateFromDate+" through "+dateToDate+""]);
            csvDataAdvertisingAndReferrals.push([]);
            csvDataAdvertisingAndReferrals.push(["Referral Source", "Uncommitted Leads", "Committed Leads", "Jobs Total Amount"]);

            axios.get(apiConfig.url.BASE_URL + apiConfig.url.REPORT_AD_REFERRAL_SOURCE, {
                params: {
                    FromDate: report.fromDate,
                    ToDate: report.toDate
                }
            })
                .then(res => {
                    res.data && res.data.length > 0 && res.data.map((item) => {
                        csvDataAdvertisingAndReferrals.push([
                            item.referralSource,
                            item.committedLeads,
                            item.uncommittedLeads,
                            (item.jobTotalAmount ? "$"+parseFloat(formatNumber(item.jobTotalAmount, 2).replace(/,/g, '')) : "$0.00"),
                        ])
                    })
                    
                    const ws = XLSX.utils.aoa_to_sheet(csvDataAdvertisingAndReferrals);
                    const wb = { Sheets: { "Advertising And Referrals": ws }, SheetNames: ["Advertising And Referrals"] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, "advertising_and_referrals_report" + fileExtension);

                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }

        if(report.report == 4) {
            setLoading(true);
            var date = new Date().toISOString();

            var dateFromDate = "";
            if(report.fromDate && report.fromDate != "") {
                var dateFromDateConverted = new Date(report.fromDate).toISOString();
                dateFromDate = moment(dateFromDateConverted).format('MM/DD/YYYY');
            }

            var dateToDate = "";
            if(report.toDate && report.toDate != "") {
                var dateToDateConverted = new Date(report.toDate).toISOString();
                dateToDate = moment(dateToDateConverted).format('MM/DD/YYYY');
            }

            csvDataCompletedJobList.push([moment(date).format('MM/DD/YYYY HH:mm:ss A')]);
            csvDataCompletedJobList.push(['Completed Jobs List']);
            csvDataCompletedJobList.push([dateFromDate+' through '+dateToDate]);
            csvDataCompletedJobList.push([]);
            csvDataCompletedJobList.push(["Date Completed", "Customer Name", "Job Address", "PrimaryEmail", "ContactName","ContactPhone","AllowText", "Amount", "Tax", "Total"]);

            axios.get(apiConfig.url.BASE_URL + apiConfig.url.REPORT_CUSTOMER_COMPLETED_JOBS, {
                params: {
                    FromDate: report.fromDate,
                    ToDate: report.toDate
                }
            })
                .then(res => {
                    res.data && res.data.length > 0 && res.data.map((item) => {
                        csvDataCompletedJobList.push([
                            (item.jobCompletedDate ? moment(item.jobCompletedDate).format('MM/DD/YYYY') : ""),
                            (item.customerName ? item.customerName : ""),
                            (item.addressName ? item.addressName+" " : "")+(item.address1 ? item.address1+" " : "")+(item.address2 ? item.address2+" " : "")+(item.city ? item.city+", " : "")+(item.state ? item.state+" " : "")+(item.zipCode ? item.zipCode+" " : ""),
                            (item.primaryEmail ? item.primaryEmail : ""),
                            (item.contactName ? item.contactName : ""),
                            (item.contactPhone ? item.contactPhone : ""),
                            (item.allowText ? item.allowText : ""),
                            (parseFloat(formatNumber(item.amount, 2).replace(/,/g, ''))),
                            (parseFloat(formatNumber(item.taxesPaid, 2).replace(/,/g, ''))),
                            (parseFloat(formatNumber(item.totalAmount, 2).replace(/,/g, ''))),
                        ])
                    })
                    
                    const ws = XLSX.utils.aoa_to_sheet(csvDataCompletedJobList);
                    const wb = { Sheets: { "Completed Job List Report": ws }, SheetNames: ["Completed Job List Report"] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, "completed_job_list_report" + fileExtension);

                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }

        if(report.report == 6) {
            setLoading(true);
            var date = new Date().toISOString();
            var dateCustomersInactiveBeforeDateConvert = "";
            if(report.customersInactiveBeforeDate && report.customersInactiveBeforeDate != "") {
                var dateCustomersInactiveBeforeDate = new Date(report.customersInactiveBeforeDate).toISOString();
                dateCustomersInactiveBeforeDateConvert = moment(dateCustomersInactiveBeforeDate).format('MM/DD/YYYY');
            }
            csvDataCustomersInactiveSince.push([moment(date).format('MM/DD/YYYY HH:mm:ss A')]);
            csvDataCustomersInactiveSince.push(['Customer Inactivity Report']);
            csvDataCustomersInactiveSince.push(['Customers Inactive Since '+dateCustomersInactiveBeforeDateConvert]);
            csvDataCustomersInactiveSince.push([]);
            csvDataCustomersInactiveSince.push(["Customer Name", "Address", "Contact name", "Phone", "Date of Last Activity" , "Email"]);

            axios.get(apiConfig.url.BASE_URL + apiConfig.url.REPORT_CUSTOMER_INACTIVITY, {
                params: {
                    FromDate: report.fromDate,
                    ToDate: report.toDate,
                    InactivityDate: report.customersInactiveBeforeDate,
                }
            })
                .then(res => {
                    res.data && res.data.length > 0 && res.data.map((item) => {
                        csvDataCustomersInactiveSince.push([
                            (item.customerName ? item.customerName : ""),
                            (item.addressName ? item.addressName+" " : "")+(item.address1 ? item.address1+" " : "")+(item.address2 ? item.address2+" " : "")+(item.city ? item.city+", " : "")+(item.state ? item.state+" " : "")+(item.zipCode ? item.zipCode+" " : ""),
                            (item.primaryContactFirstName ? item.primaryContactFirstName+" " : "")+(item.primaryContactLastName ? item.primaryContactLastName : ""),
                            (item.phoneNumbers ? item.phoneNumbers : ""),
                            (item.lastActivity ? moment(item.lastActivity).format('MM/DD/YYYY') : ""),
                            (item.email ? item.email : ""),
                        ])
                    })
                    
                    const ws = XLSX.utils.aoa_to_sheet(csvDataCustomersInactiveSince);
                    const wb = { Sheets: { "Customer Inactivity Report": ws }, SheetNames: ["Customer Inactivity Report"] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, "customer_inactivity_report" + fileExtension);

                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }

        if(report.report == 7) {
            setLoading(true);
            var date = new Date().toISOString();
            csvDataCustomersService.push([moment(date).format('MM/DD/YYYY HH:mm:ss A')]);
            csvDataCustomersService.push(['Unresolved Customer Service Calls']);
            csvDataCustomersService.push([]);
            csvDataCustomersService.push(["Customer Name Job Description", "Job Address", "Contact name", "Phone", "Job Notes", "Date of Initial Call Bid Appointment Time" , "Email"]);

            axios.get(apiConfig.url.BASE_URL + apiConfig.url.REPORT_CUSTOMER_SERVICE, {
                params: { }
            })
                .then(res => {
                    res.data && res.data.length > 0 && res.data.map((item) => {
                        csvDataCustomersService.push([
                            (item.customerName ? item.customerName+" "+item.description : ""),
                            (item.addressName ? item.addressName+" " : "")+(item.address1 ? item.address1+" " : "")+(item.address2 ? item.address2+" " : "")+(item.city ? item.city+", " : "")+(item.state ? item.state+" " : "")+(item.zipCode ? item.zipCode+" " : ""),
                            (item.primaryContactName ? item.primaryContactName : ""),
                            (item.primaryContactPhone ? item.primaryContactPhone : ""),
                            (item.jobNote ? item.jobNote : ""),
                            (item.initialCallDate ? moment(item.initialCallDate).format('MM/DD/YYYY h:mm A') : "") + " " + (item.bidAppointment ?  moment(item.bidAppointment+'.000Z').format('MM/DD/YYYY h:mm A') : "No Bid Scheduled"),
                            (item.email ? item.email : ""),
                        ])
                    })
                    
                    const ws = XLSX.utils.aoa_to_sheet(csvDataCustomersService);
                    const wb = { Sheets: { "Customer Service": ws }, SheetNames: ["Customer Service"] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, "unresolved_customer_service_calls" + fileExtension);

                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }

        if (userRole === PERMISSIONS.SUPER_ADMIN && report.report == 8) {
            setLoading(true);
            var date = new Date().toISOString();

            var dateFromDate = "";
            if(report.fromDate && report.fromDate != "") {
                var dateFromDateConverted = new Date(report.fromDate).toISOString();
                dateFromDate = moment(dateFromDateConverted).format('MM/DD/YYYY');
            }

            var dateToDate = "";
            if(report.toDate && report.toDate != "") {
                var dateToDateConverted = new Date(report.toDate).toISOString();
                dateToDate = moment(dateToDateConverted).format('MM/DD/YYYY');
            }

            csvDataGlobalAdvertisingAndReferrals.push(["Referral Source for "+dateFromDate+" through "+dateToDate+""]);
            csvDataGlobalAdvertisingAndReferrals.push([]);
            csvDataGlobalAdvertisingAndReferrals.push(["Referral Source", "Uncommitted Leads", "Committed Leads", "Jobs Total Amount"]);

            axios.get(apiConfig.url.BASE_URL + apiConfig.url.REPORT_GLOBAL_AD_REFERRAL_SOURCE, {
                params: {
                    FromDate: report.fromDate,
                    ToDate: report.toDate
                }
            })
                .then(res => {
                    res.data && res.data.length > 0 && res.data.map((item) => {
                        csvDataGlobalAdvertisingAndReferrals.push([
                            item.referralSource,
                            item.committedLeads,
                            item.uncommittedLeads,
                            (item.jobTotalAmount ? "$"+parseFloat(formatNumber(item.jobTotalAmount, 2).replace(/,/g, '')) : "$0.00"),
                        ])
                    })
                    
                    const ws = XLSX.utils.aoa_to_sheet(csvDataGlobalAdvertisingAndReferrals);
                    const wb = { Sheets: { "Global Ads and Referral Source": ws }, SheetNames: ["Global Ads and Referral Source"] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, "global_advertising_and_referral_source" + fileExtension);

                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }

        if (report.report == 9) {
            setLoading(true);
            var date = new Date().toISOString();

            var dateFromDate = "";
            if(report.fromDate && report.fromDate != "") {
                var dateFromDateConverted = new Date(report.fromDate).toISOString();
                dateFromDate = moment(dateFromDateConverted).format('MM/DD/YYYY');
            }

            var dateToDate = "";
            if(report.toDate && report.toDate != "") {
                var dateToDateConverted = new Date(report.toDate).toISOString();
                dateToDate = moment(dateToDateConverted).format('MM/DD/YYYY');
            }

            csvDataGlobalNiceJobs.push(["Name", "Email", "Phone", "Company", "Position", "Address1", "City", "Zip", "Country"]);

            axios.get(apiConfig.url.BASE_URL + apiConfig.url.REPORT_GLOBAL_NICE_JOB, {
                params: {
                    FromDate: report.fromDate,
                    ToDate: report.toDate
                }
            })
                .then(res => {
                    res.data && res.data.length > 0 && res.data.map((item) => {
                        csvDataGlobalNiceJobs.push([
                            item.firstName,
                            item.email,
                            item.phone,
                            item.company,
                            item.position,
                            item.address1,
                            item.city,
                            item.zip,
                            item.country
                        ])
                    })
                    
                    const ws = XLSX.utils.aoa_to_sheet(csvDataGlobalNiceJobs);
                    const wb = { Sheets: { "NiceJob": ws }, SheetNames: ["NiceJob"] };
                    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
                    const data = new Blob([excelBuffer], {type: fileType});
                    FileSaver.saveAs(data, "nice_job" + '.csv');

                }).catch(ex => {
                }).finally(() => {
                    setLoading(false);
                })
        }
    }

    return (
        <AccessControl requiredPermission={PERMISSIONS.page.reports} redirect>
            <Page title={'Report'}>
                <div style={{ padding: 32 }}>
                    <Card>
                        <CardHeader title='Reports' />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={2}
                                style={report.report === '6' ? { justifyContent: 'center' } : null}>
                                <Grid item xs={12} md={4}>
                                    <Typography className={classes.fieldTitle}>Select Reports:</Typography>
                                    <TextField
                                        fullWidth
                                        label='Report'
                                        name='report'
                                        variant='outlined'
                                        select
                                        SelectProps={{ native: true }}
                                        value={report.report}
                                        onChange={event => onChangeReport(event.target.name, event.target.value)}>
                                        {reports.map(rp => (
                                            <option key={`report-${rp.id}`} value={rp.id}>{rp.name}</option>
                                        ))}
                                        {userRole === PERMISSIONS.SUPER_ADMIN && <option key={`report-8`} value={8}>Global Advertising and Referral Source</option>}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={4}
                                    style={report.report === '7' ? { display: 'none' } : null}>
                                    <Typography className={classes.fieldTitle}>
                                        {report.report === '5' || report.report === '6' ?
                                            'Select Customers Added Between:' : 'Select Date Range:'
                                        }
                                    </Typography>
                                    <KeyboardDatePicker
                                        disabled={!report.report}
                                        label='From Date'
                                        name='fromDate'
                                        value={report.fromDate || null}
                                        onShowDatePicker={() => onShowDatePicker('fromDate')}
                                        onChange={date => onChangeReport('fromDate', date)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.toDate}
                                    style={report.report === '7' ? { display: 'none' } : null}>
                                    <KeyboardDatePicker
                                        disabled={!report.fromDate || !report.report}
                                        label='To Date'
                                        name='toDate'
                                        minDate={report.fromDate}
                                        value={report.toDate || null}
                                        onShowDatePicker={() => onShowDatePicker('toDate')}
                                        onChange={date => onChangeReport('toDate', date)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}
                                    style={!report.report || report.report !== '6' || report.report === '7' ? { display: 'none' } : null}>
                                    <KeyboardDatePicker
                                        label='Customers Inactive Before Date'
                                        name='customersInactiveBeforeDate'

                                        value={report.customersInactiveBeforeDate || null}
                                        onShowDatePicker={() => onShowDatePicker('customersInactiveBeforeDate')}
                                        onChange={date => onChangeReport('customersInactiveBeforeDate', date)}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions style={{ justifyContent: 'flex-end' }}>
                            <Button 
                                className={(!report.report || !report.fromDate || !report.toDate || loading) ? (report && report.report == 7 ? classes.btn : classes.btnDis) : classes.btn}
                                onClick={handleReport}
                            >
                                Run Report
                            </Button>
                            {loading && <CircularProgress  size={30} />}
                        </CardActions>
                    </Card>

                    <DatePicker
                        style={{ display: 'none' }}
                        open={openPicker}
                        minDate={typeDate === 'toDate' ? minDate(report.fromDate) : new Date(1900, 0, 1)}
                        maxDate={typeDate === 'fromDate' ? ((report.toDate && maxDate(report.toDate)) || new Date(2100, 0, 1)) : new Date(2100, 0, 1)}
                        onClose={() => setOpenPicker(false)}
                        format="MM/DD/YYYY"
                        onChange={onHandleChangeDate}
                    />
                </div>
            </Page>
        </AccessControl>
    )
}

export default Report;
