/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
! function(t, e) {
    "object" == typeof exports && "object" == typeof module ? module.exports = e() : "function" == typeof define && define.amd ? define([], e) : "object" == typeof exports ? exports.Tokenizer = e() : t.Tokenizer = e()
}(self, (function() {
    return function() {
        "use strict";
        var t = {};
        return function() {
            var e = t,
                n = "/api/tokenizer",
                i = function() {
                    function t(t) {
                        var e = this;
                        if (this.container = null, this.settings = {
                                id: "",
                                apikey: "",
                                amount: ""
                            }, this.onLoad = function() {}, this.validCard = function() {}, this.achOnChange = function() {}, this.magStripeSwipe = function() {}, this.onPaymentChange = function() {}, this.submission = function() {}, !t.apikey) throw new Error("apikey must be set!");
                        this.apikey = t.apikey, this.url = t.url && "" !== t.url ? t.url : "https://app.gotnpgateway.com", t.url || -1 === window.location.href.indexOf("dev.squadware") ? this.url = this.url.replace(/\/$/, "") + n : this.url = "https://sandbox.gotnpgateway.com/".replace(/\/$/, "") + n, this.url += "/" + this.apikey, t.amount && (this.amount = t.amount), this.id = this.uuid(), "object" == typeof t.settings && (this.settings = t.settings), t.onLoad && (this.onLoad = t.onLoad), t.validCard && (this.validCard = t.validCard), t.achOnChange && (this.achOnChange = t.achOnChange), t.magStripeSwipe && (this.magStripeSwipe = t.magStripeSwipe), t.onPaymentChange && (this.onPaymentChange = t.onPaymentChange), t.submission && (this.submission = t.submission), window.addEventListener("message", (function(t) {
                            e.messageListener(t)
                        })), this.iframe = this.buildIframe(), this.waitForContainer(t.container, (function(t) {
                            if (!t) throw new Error("Could not find container");
                            e.iframe.onload = function() {
                                e.settings.id = e.id, e.settings.apikey = e.apikey, e.settings.amount = e.amount, e.setSettings(e.settings), e.onLoad()
                            }, e.container = t, e.container.appendChild(e.iframe)
                        }))
                    }
                    return t.prototype.create = function() {}, t.prototype.isSurchargeable = function(t, e) {
                        return "" !== t && -1 === ["CO", "CT", "ME", "MA"].indexOf(t.toUpperCase()) && !(!e || !e.card_type || "credit" !== e.card_type.toLowerCase())
                    }, t.prototype.submit = function(t) {
                        var e = this;
                        this.getGuardianData().then((function(t) {
                            var n;
                            (null === (n = t.events) || void 0 === n ? void 0 : n.length) && e.postMessage({
                                event: "setGuardian",
                                data: t
                            })
                        })).then((function() {
                            e.postMessage({
                                event: "submit",
                                data: {
                                    amount: t
                                }
                            })
                        })).catch((function() {
                            e.postMessage({
                                event: "submit",
                                data: {
                                    amount: t
                                }
                            })
                        }))
                    }, t.prototype.setExpDate = function(t) {
                        this.postMessage({
                            event: "setExpDate",
                            data: {
                                value: t
                            }
                        })
                    }, t.prototype.postMessage = function(t) {
                        var e = this.iframe.contentWindow;
                        if (null !== e) {
                            var n = JSON.stringify(t);
                            e.postMessage(n, "*")
                        }
                    }, t.prototype.uuid = function() {
                        function t() {
                            return Math.floor(65536 * (1 + Math.random())).toString(16).substring(1)
                        }
                        return t() + t() + "-" + t() + "-" + t() + "-" + t() + "-" + t() + t() + t()
                    }, t.prototype.waitForContainer = function(t, e) {
                        if (t) {
                            var n = Date.now();
                            ! function i() {
                                "string" != typeof t || null === document.querySelector(t) ? "string" != typeof t && t ? e(t) : setTimeout((function() {
                                    Date.now() - n > 1e4 ? e(null) : i()
                                }), 1e3) : e(document.querySelector(t))
                            }()
                        } else e(null)
                    }, t.prototype.buildIframe = function() {
                        var t = document.createElement("iframe");
                        return t.src = this.url, t.setAttribute("allow", "payment"), t.style.display = "block", t.style.border = "none", t.style.margin = "0px", t.style.padding = "0px", t.style.width = "1px", t.style.minWidth = "100%", t.style.height = "30px", t.style.overflow = "hidden", t.style.backgroundColor = "transparent", t.style.transition = "all .3s ease-out", t.frameBorder = "0", t
                    }, t.prototype.setSettings = function(t) {
                        this.postMessage({
                            event: "setSettings",
                            data: t
                        })
                    }, t.prototype.updateHeight = function(t) {
                        t && (this.iframe.style.height = t + "px")
                    }, t.prototype.getGuardianData = function() {
                        var t = window.Guardian;
                        return t && t.getData && "function" == typeof t.getData ? t.getData() : Promise.reject()
                    }, t.prototype.messageListener = function(t) {
                        try {
                            var e = JSON.parse(t.data),
                                n = e.id;
                            if (this.id !== n) return;
                            var i = e.event,
                                a = e.data;
                            if (!i) return;
                            switch (i) {
                                case "submission":
                                    this.submission(a);
                                    break;
                                case "validCard":
                                    this.validCard(a);
                                    break;
                                case "achOnChange":
                                    this.achOnChange(a);
                                    break;
                                case "magStripeSwipe":
                                    this.magStripeSwipe(a);
                                    break;
                                case "onPaymentChange":
                                    this.onPaymentChange(a.type);
                                    break;
                                case "setHeight":
                                    this.updateHeight(a.height)
                            }
                        } catch (t) {}
                    }, t
                }();
            e.default = i
        }(), t.default
    }()
}));