import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const EmployeeToolbar = props => {
  const { onSearch, onClear, value, onChange, archived, handleArchivedChange, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest}
      className={clsx(classes.root, className)}>
      <Grid item xs={12}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search customers and employees..."
          onSearch={onSearch}
          onClear={onClear}
          value={value}
          onChange={onChange}
          showSearchButton={true}
          hideArchived={true}
          archived={archived}
          handleArchivedChange={handleArchivedChange}
        />
      </Grid>
    </div>
  );
};

EmployeeToolbar.propTypes = {
  className: PropTypes.string
};

export default EmployeeToolbar;
