import React from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input, Button , FormControlLabel, TextField ,colors} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { GreenCheckbox } from 'components';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  clearIcon: {
    marginRight: theme.spacing(1)
  },
  searchButton: {
    marginLeft: theme.spacing(2)
  },
  searchPaper: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    width: '100%'
  },
  hideArchivedCheckbox: {
    marginLeft: theme.spacing(1)
  },
  btnSearch: {
    [theme.breakpoints.down("sm")]: {
    },
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900]
    }
  },
}));

const SearchInput = props => {
  const {
    className,
    placeholder,
    onSearch,
    onClear,
    value,
    onChange,
    style,
    showSearchButton,
    searchAllFields,
    searchJobsites,
    hideArchived,
    handleArchivedChange,
    archived,
    addressSearch,
    handleAddressSearchChange,
    searchField,
    handleSearchFieldChange,
    ...rest
  } = props;
  const classes = useStyles();
  const searchFields = [
    {id: 0, value : 'Search all fields'},
    {id: 1, value : 'Customer Name'},
    {id: 2, value : 'Contact Name'},
    {id: 3, value : 'Street Number'},
    {id: 4, value : 'Street Name'},
    {id: 5, value : 'City'},
    {id: 6, value : 'Phone Number'},
    {id: 7, value : 'Email'},
    {id: 8, value : 'Invoice Number'}
];
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
      <Paper
        className={classes.searchPaper}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          {...rest}
          className={classes.input}
          disableUnderline
          placeholder={placeholder}
          value={value}
          onChange={event => onChange(event)}
          onKeyUp={(ev) => {
            if (ev.key === 'Enter') {
              onSearch();
            }
          }}
        />
      </Paper>
      {showSearchButton && <Button
        className={clsx(classes.searchButton, classes.btnSearch)}
        onClick={onSearch}
        size="large"
        variant="contained">
        {/* <SearchIcon className={classes.searchIcon} /> */}
        Search
      </Button>
      }
      <Button
        disabled={value.length === 0}
        className={classes.searchButton}
        onClick={onClear}
        size="large"
        variant="contained">
        <ClearIcon className={classes.clearIcon} />
        Clear
      </Button>
      {searchAllFields && <TextField
                    label=''
                    name='searchField'
                    variant='outlined'
                    size='small'
                    className={classes.hideArchivedCheckbox}
                    select
                    SelectProps={{ native: true }}
                    value={searchField || '0'}
                    onChange={e => handleSearchFieldChange(e)}
                  >
                    {searchFields.map(f => (
                        <option key={f.id} value={f.id}>
                            {f.value}
                        </option>
                    ))}
                  </TextField>}
      {searchJobsites && <FormControlLabel
            className={classes.hideArchivedCheckbox}
            name='isAddressSearch'
            control={<GreenCheckbox />}
            label="Search Jobsites"
            checked={addressSearch || false}
            onChange={e => handleAddressSearchChange(e)}
            />}
      {hideArchived &&  <FormControlLabel
            className={classes.hideArchivedCheckbox}
            name='isDeleted'
            control={<GreenCheckbox />}
            label="Hide Archived"
            checked={archived || false}
            onChange={e => handleArchivedChange(e)}
        />}
    </div>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
