import React, {useEffect, useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import axios from "utils/axios";
import apiConfig from "apiConfig";

const useStyles = makeStyles(theme => ({
    
}));

const styles = StyleSheet.create({
    invoiceSection: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: 20
    },
    sectionOne: {
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    textBold: {
        fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '10px'
    },
    sectionDateAndInvoice: {
        width: '50%', height: 'auto', flexDirection: 'row'
    },
    sectionChildDateAndInvoice: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '50%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTwo: {
        marginTop: 50, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    sectionThree: {
        marginTop: 10, 
        flexDirection: "row",
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center'
    },
    dueDate: {
        fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10, width: '100%', border: 1, textAlign: 'center', padding: 6
    },
    sectionTable: {
        width: '100%', height: 'auto', flexDirection: 'row'
    }
});

const ThankYouNodePdf = (props) => {
    const classes = useStyles();

    Font.register({
        family: 'Roboto',
        fonts: [
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Regular.woff`,
                fontWeight: 'medium'
            },
            {
                src: `https://cdn.jsdelivr.net/npm/roboto-fontface@0.10.0/fonts/roboto/Roboto-Bold.woff`,
                fontWeight: 'bold'
            }
        ]
    })

    return (
        <>
            <Document>
                <Page size="LETTER" style={{ padding: '5.75mm', paddingTop: '13.75mm', paddingBottom: '12.7mm' }}>
                    {props.data && Array.isArray(props.data) && props.data.length > 0 && props.data.map(function (item, index) {
                        return <View key={index} style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%' }}>
                            {item && Array.isArray(item) && item.length > 0 && item.map(function (itemChild, indexChild) {
                                return <View key={indexChild} style={{ width: '33.3%', height: '25.05mm', paddingTop:'5mm',paddingLeft:'5mm' }}>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {itemChild.customerName}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {(itemChild.addressName ? itemChild.addressName+" " : "")}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {(itemChild.address1 ? itemChild.address1+" " : "")}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {(itemChild.address2 ? itemChild.address2+" " : "")}
                                    </Text>
                                    <Text style={{ fontFamily: 'Roboto', fontWeight: 'medium', fontSize: 10 }}>
                                        {(itemChild.city ? itemChild.city+", " : "")+(itemChild.state ? itemChild.state+" " : "")+(itemChild.zipCode ? itemChild.zipCode+" " : "")}
                                    </Text>

                                </View>
                            })}
                        </View>
                    })}
                </Page>
            </Document>
        </>
    )
}

ThankYouNodePdf.propTypes = {
    
}

export default ThankYouNodePdf;
